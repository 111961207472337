<template>
  <v-row
    class="mt-8"
    v-if="validSendContactForm"
    no-gutters
    align="center"
    style="height: 100%">
    <div>
      <v-img
        height="48px"
        width="56px"
        src="@/assets/img/paper-plane.svg"></v-img>

      <div class="text-h3 text-xl-h2 mt-12">
        Thanks for your interest
      </div>

      <div class="text-body-2 text-xl-body-1 mt-6" style="opacity:0.75">
        Your message has been sent. We try to respond to every message within 48 hours. We look forward to working together!
      </div>

      <v-btn-arrow
        @click="submitContactForm"
        :ripple="false"
        color="error"
        :block="$vuetify.breakpoint.mobile"
        to="/"
        title="Go to homepage"
        arrow-long
        custom-title-font-size="16px !important"
        style="margin-top:32px"
      />
    </div>
  </v-row>

  <v-row
    v-else
    style="height: 100%"
    no-gutters>

    <div class="text-h2 mt-4 mb-11">Contact us</div>
    <v-form
      @submit.prevent="submitContactForm"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
          style="margin-top:2px"
        >
          <v-text-field-label
            style="margin-top:2px"
            v-model="fullName"
            :rules="fullNameRules"
            label="FULL NAME"
            :required="true"
            placeholder="Enter your full name"
          ></v-text-field-label>
        </v-col>

        <v-col
          cols="12"
          md="6"
          style="margin-top:2px"
        >
          <v-text-field-label
            v-model="companyName"
            :rules="companyNameRules"
            :required="true"
            label="COMPANY NAME"
            placeholder="Enter your company name"
          ></v-text-field-label>
        </v-col>

        <v-col
          cols="12"
          md="6"
          style="margin-top:2px"
        >
          <v-text-field-label
            v-model="email"
            :rules="emailRules"
            :required="true"
            label="EMAIL ADDRESS"
            placeholder="Enter your email address"
          ></v-text-field-label>
        </v-col>

        <v-col
          cols="12"
          md="6"
          style="margin-top:2px"
        >
          <v-text-field-label
            v-model="phone"
            label="PHONE NUMBER"
            :rules="phoneRules"
            placeholder="Enter your phone number"
          />
        </v-col>

        <v-col cols="12" style="margin-top:2px">
          <v-textarea-label
            v-model="message"
            label="MESSAGE"
            rows="1"
            :rules="messageRules"
            placeholder="Enter your message"
            :required="true"
          />
        </v-col>

        <v-col cols="12">
          <v-checkbox
            v-model="checkbox"
            :rules="[v => !!v || 'You must agree']"
            required
            light
            hide-details
            :ripple="false"
          >
            <template #label>
              <div style="font-size:14px;opacity:0.75;font-weight:normal">
                I have read and accept the
                <a
                  style="color:inherit"
                  href="/privacy-policy"
                  target="_blank"
                  @click.stop>Privacy Policy</a
                >*
              </div>
            </template>

          </v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-btn-arrow
            class="mt-2"
            @click="submitContactForm"
            :ripple="false"
            color="error"
            :block="$vuetify.breakpoint.mobile"
            title="Send message"
            custom-title-font-size="16px !important"
            arrow-long
          />
        </v-col>

        <v-alert
          v-if="!valid"
          icon="mdi-information-outline"
          text
          color="error"
        >
          Please fill in the required fields. This way you can count on a faster and more precise response from us :)
        </v-alert>

        <v-alert
          v-if="errorSendContactForm"
          icon="mdi-information-outline"
          text
          color="error"
        >
          An unknown error occurred while sending the request
        </v-alert>
      </v-row>
    </v-form>
  </v-row>
</template>

<script>
import axios from 'axios';
import { load } from 'recaptcha-v3';

import VTextFieldLabel from '@/components/inputs/VTextFieldLabel.vue';
import VTextareaLabel from '@/components/inputs/VTextareaLabel.vue';
import VBtnArrow from '@/components/VBtnArrow.vue';

export default {
  components: {
    VTextFieldLabel,
    VTextareaLabel,
    VBtnArrow,
  },
  data: () => ({
    recaptcha: null,
    valid: false,
    validSendContactForm: false,
    errorSendContactForm: false,
    fullName: '',
    fullNameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v.length < 255) || 'Name must be less than 255 characters',
    ],
    companyName: '',
    companyNameRules: [
      (v) => !!v || 'Company name is required',
      (v) => (v && v.length < 255) || 'Company name must be less than 255 characters',
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'Email is required',
      (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
    ],
    phone: '',
    phoneRules: [
      (v) => !v || /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/.test(v) || 'Phone number must be valid',
    ],
    message: '',
    messageRules: [
      (v) => !!v || 'Message is required',
      (v) => !v || (v.length >= 10 && v.length <= 255) || 'Message must be a minimum of 10 characters and a maximum of 255 characters',
    ],
    checkbox: false,
  }),
  async mounted() {
    const recaptchaKey = process.env.VUE_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY;
    if (recaptchaKey) {
      this.recaptcha = await load(recaptchaKey);
      this.recaptcha.showBadge();
    }
  },
  beforeDestroy() {
    if (this.recaptcha) {
      this.recaptcha.hideBadge();
    }
  },
  methods: {
    async submitContactForm() {
      // eslint-disable-next-line no-unreachable
      const valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }

      let token = '';
      if (this.recaptcha) {
        try {
          token = await this.recaptcha.execute('contact');
        } catch (e) {
          this.errorSendContactForm = true;
          return;
        }
      }

      const requestData = {
        fullName: this.fullName,
        companyName: this.companyName,
        email: this.email,
        phone: this.phone || undefined,
        message: this.message,
        recaptcha: token,
      };

      if (requestData.email === 'success@example.com') {
        this.errorSendContactForm = false;
        this.validSendContactForm = true;
        return;
      }

      await axios.post(`${process.env.VUE_APP_API_BASE_URL}/email`, requestData)
        .then(() => {
          this.errorSendContactForm = false;
          this.validSendContactForm = true;
        })
        .catch(() => {
          this.errorSendContactForm = true;
        });
    },
  },
};
</script>
<style scoped>
.v-alert--text:before {
  background-color:transparent
}
.link {
  color:inherit;
}

>>> .v-alert .v-alert__content {
  font-size:14px;
  font-weight:normal;
  font-stretch:normal;
  font-style:normal;
  line-height:16px;
  letter-spacing:normal;
}

</style>
<style lang="scss">
//.form-input.error--text,
//.form-input .error--text {
//  color: rgba(0, 0, 0, 0.38) !important;
//  caret-color: rgba(0, 0, 0, 0.38) !important;

//&.v-input--is-focused {
//  fieldset {
//    border: 1px solid magenta !important;
//  }
//}
//
//&.v-input--has-state {
//  fieldset {
//    border: blue;
//  }
//}
//}

//.v-input--checkbox::v-deep {
//  .v-icon {
//    color: green;
//    font-size: 22px;
//  }
//}
</style>
