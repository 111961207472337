<template>
  <div>
    <v-container class="py-0 container-wrapper">
      <v-row
        no-gutters
      >
        <v-col cols="12" xl="4">
          <v-row>
            <v-col>
              <span class="vertical-line"></span>
            </v-col>
            <v-col style="text-align: right">
              <span class="vertical-line"></span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="$vuetify.breakpoint.mobile" style="padding-top:160px;background-color:#231a13"></div>

    <div v-if="$vuetify.breakpoint.xlOnly" style="background-color: #231a13;height: 100%;position:fixed;width:33%;top:0;bottom:0;"></div>

    <v-container v-if="$vuetify.breakpoint.xlOnly" class="container-wrapper py-0">
      <v-row
        class="my-0"
      >
        <v-col
          cols="12"
          xl="5"
          class="pa-0"
          :style="{'height': $vuetify.breakpoint.xlOnly ? '100vh' : null}"
          style="background-color: #231a13">
          <div style="background-color: #231a13;">
            <v-card
              flat
              tile
              class="card-1"
            >
              <v-row no-gutters class="fill-height flex-column flex-lg-row">
                <div class="pr-lg-6" style="max-width:380px">
                  <div class="text-h2 text-xl-h1 white--text">
                    We’d love to hear
                    from you
                  </div>

                  <div class="text-body-2 text-xl-body-1 white--text mt-6 mt-xl-12" style="max-width:476px;opacity:0.75">
                    Please send us a message and our representative will respond to you shortly to discuss details.
                  </div>

                  <div class="mt-6 mt-xl-12">
                    <div class="white--text" style="opacity: 0.75">You can also email us at</div>
                    <a class="white--text" href="mailto:info@devonshireassociates.uk">info@devonshireassociates.uk</a>
                  </div>

                  <div style="border-left:1px solid red;" class="white--text mt-6 mb-16 address ml-n3">
                    <div
                      class="pl-6 text-body-2"
                      style="opacity: 0.75">
                      Chiswick Gate<br/>
                      598-608 Chiswick High Rd<br/>
                      London, W4 5RT<br/>
                      United Kingdom
                    </div>
                  </div>
                </div>
              </v-row>
            </v-card>
          </div>
        </v-col>
        <v-col
          cols="12"
          xl="7"
          class="pa-0"
        >
          <v-card
            tile
            flat
            class="card-2"
          >
            <ContactForm/>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div v-else>
      <div style="background-color: #231a13;">
        <v-container class="container-wrapper py-0">
          <v-row
            class="my-0"
          >
            <v-col
              cols="12"
              class="pa-0"
              style="background-color: #231a13">

              <v-card
                flat
                tile
                class="card-1"
              >
                <v-row no-gutters class="fill-height flex-column flex-lg-row">
                  <div class="pr-lg-6" style="max-width:380px">
                    <div class="text-h2 text-xl-h1 white--text">
                      We’d love to hear
                      from you
                    </div>

                    <div class="text-body-2 text-xl-body-1 white--text mt-6 mt-xl-12" style="max-width:476px;opacity:0.75">
                      Please send us a message and our representative will respond to you shortly to discuss details.
                    </div>

                    <div class="mt-6 mt-xl-12">
                      <div class="white--text" style="opacity: 0.75">You can also email us at</div>
                      <a class="white--text" href="mailto:info@devonshireassociates.uk">info@devonshireassociates.uk</a>
                    </div>

                    <div style="border-left:1px solid red;" class="white--text mt-6 mb-16 address ml-n3">
                      <div
                        class="pl-6 text-body-2"
                        style="opacity: 0.75">
                        Chiswick Gate<br/>
                        598-608 Chiswick High Rd<br/>
                        London, W4 5RT<br/>
                        United Kingdom
                      </div>
                    </div>
                  </div>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-container class="container-wrapper py-0">
        <v-row>
          <v-col
            cols="12"
          >
            <v-card
              tile
              flat
              class="card-2"
            >
              <ContactForm/>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue';

export default {
  components: {
    ContactForm,
  },
};
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.card-1 {
  padding-right: 24px;
  padding-left: 24px;

  background-color: #231a13;

  @media #{map-get($display-breakpoints, 'xl-only')}{
    margin-top: 248px;
    //padding-top: 50px;
    //padding-right: 0;
    //padding-left: 44px;
    //padding-bottom: 0;
  }
}

.card-2 {
  padding: 32px 24px;

  @media #{map-get($display-breakpoints, 'xl-only')}{
    padding: 204px 80px 70px;
  }
}

.container-constrain {
  @media #{map-get($display-breakpoints, 'xl-only')} {
    margin-top: 132px;
    padding-left: 100px;
    padding-right: 100px;
  }
}

.address {
  @media #{map-get($display-breakpoints, 'xl-only')} {
    margin-top: 120px !important;
  }
}

</style>
