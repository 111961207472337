<template>
  <div>
    <label
      v-bind="{for: this.for()}"
      :class="{'error--text': false &&fieldError}"
      class="v-label theme--light"
      style="opacity: 0.75">{{ $attrs.label }}{{
        $attrs.required
          ? '*'
          : ''
      }}</label>
    <v-text-field
      ref="field"
      v-model="innerValue"
      v-bind="$attrs"
      v-on="$listeners"
      label=""
      dense
      :name="$attrs.name || $attrs.label"
      @blur="checkFieldError"
      @update:error="checkFieldError"
      hide-details
      @change="checkFieldError"
    />
  </div>
</template>

<script>

export default {
  inheritAttrs: false,
  props: {
    value: {
      type: null,
    },
  },
  data: () => ({
    innerValue: '',
    fieldError: false,
    fieldErrorCount: null,
  }),
  mounted() {
    this.$watch(
      () => this.$refs.field.validationState,
      (newVal) => {
        if (newVal === 'error') {
          this.checkFieldError();
        }
      },
    );
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
      this.checkFieldError();
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  computed: {},
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    for() {
      return this.$attrs.name || this.$attrs.label;
    },
    checkFieldError() {
      this.fieldError = this.$refs.field?.valid === false;
    },
  },
};
</script>
<style lang="scss">
.v-text-field {
  input {
    color: var(--v-secondary-base) !important;
  }

  &.primary--text {
    color: unset !important;
    caret-color: unset !important;
  }

  &.v-input--is-focused {
    .v-input__slot {
      border-bottom-color: rgba(0, 0, 0, 0.38) !important;
    }
  }

  &.error--text {
    input {
      color: var(--v-error-base) !important;
      &::placeholder {
        color: var(--v-error-base) !important;
      }
    }
  }
}
</style>
